.container {
  position: fixed;
  z-index: 5;
  right: 20px;
  bottom: 20px;
}

.button {
  all: unset;
  display: block;
  cursor: pointer;
}

.content {
  display: block;
  overflow: hidden;
  width: 50px;
  height: 50px;
  font-size: 0;
  background-color: var(--accent-main-primary);
  background-image: url('./assets/chat.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
  border-radius: 50px;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.1),
    inset 0 -2px 5px rgba(0, 0, 0, 0.05);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 50px;
  height: 50px;
  font-size: 0;
  background-color: var(--accent-main-primary);
  border-radius: 50px;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.1),
    inset 0 -2px 5px rgba(0, 0, 0, 0.05);
}
