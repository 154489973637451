.container {
  padding: 16px 20px 24px;
}

.container::before {
  margin: 16px auto 24px;
  display: block;
  width: 64px;
  height: 64px;
  content: '';
  background-image: url('./assets/error.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.title {
  margin-bottom: 24px;
  text-align: center;
}

@media screen and (min-width: 641px) {
  .container {
    padding: 16px 40px 40px;
  }

  .title {
    margin-bottom: 16px;
  }
}
